.highlight {
 .hll { background-color: #ffffcc }
 .c, .cm, .c1 { color: #4e9a06; } /* Comment, Comment.Multiline, Comment.Single */
 .cs { color: #4e9a06; font-weight: bold } /* Comment.Special */

 .cp { color: #008080; } /* Comment.Preproc */

 .err { color: #a61717; background-color: #e3d2d2 } /* Error */
 .k  { color: #000080; } /* Keyword */
 .nd { color: #000080; } /* Annotation */
 .gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
 .ge { font-style: italic } /* Generic.Emph */
 .gr { color: #aa0000 } /* Generic.Error */
 .gh { color: #999999 } /* Generic.Heading */
 .gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
 .go { color: #888888 } /* Generic.Output */
 .gp { color: #555555 } /* Generic.Prompt */
 .gs { font-weight: bold } /* Generic.Strong */
 .gu { color: #aaaaaa } /* Generic.Subheading */
 .gt { color: #aa0000 } /* Generic.Traceback */
 .kc { color: #000080 } /* Keyword.Constant */
 .kd { color: #000080 } /* Keyword.Declaration */
 .kn { color: #000080 } /* Keyword.Namespace */
 .kp { color: #000080 } /* Keyword.Pseudo */
 .kr { color: #000080 } /* Keyword.Reserved */
 .kt { color: #000080 } /* Keyword.Type */

 /* Literal.Number, Literal.Number.Float, Literal.Number.Hex, Literal.Number.Integer, Literal.Number.Oct, Literal.String.Symbol, Literal.Number.Integer.Long */
 .m, .mf, .mh, .mi, .mo, .ss, .il { color: #823b49 }

 /* Literal.String, Literal.String.Single, Literal.String.Double */
 .s, .s1, .s2 { color: #72417f }


 .na { } /* Name.Attribute */

 .nt { color: #000080; font-weight: bold } /* Name.Tag */
 .ow { font-weight: bold } /* Operator.Word */
 .w { color: #bbbbbb } /* Text.Whitespace */

 .sb { color: #0000FF } /* Literal.String.Backtick */
 .sc { color: #800080 } /* Literal.String.Char */
 .sd { color: #0000FF } /* Literal.String.Doc */
 .se { color: #0000FF } /* Literal.String.Escape */
 .sh { color: #0000FF } /* Literal.String.Heredoc */
 .si { color: #0000FF } /* Literal.String.Interpol */
 .sx { color: #0000FF } /* Literal.String.Other */
 .sr { color: #0000FF } /* Literal.String.Regex */
}
